// Copyright (C) 2022 Probayes
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import 'leaflet/dist/leaflet.css';
import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    LayersControl,
    Polyline,
} from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [16, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Props {
    initialCenter: LatLngTuple;
}

interface Message {
    id: number;
    coords: LatLngTuple;
}

function MapEventHandler({ initialCenter = [45.2167723, 5.8073047] }: Props): JSX.Element | null {
    const map = useMap();
    const [position, setPosition] = useState<LatLngTuple>(initialCenter);

    const { tid, jid } = useParams<{ tid: string, jid: string }>();

    const [bc] = useState(new BroadcastChannel(`cvat_${tid}_${jid}`));

    bc.onmessage = (e: MessageEvent<Message>) => {
        // console.log('Read!', e.data);
        setPosition(e.data.coords);
        map.setView(e.data.coords, 18);
    };
    return (
        <Marker position={position} />
    );
}

const colors = ['black', 'red', 'yellow', 'grey', 'green', 'purple', 'brown'];

function ReferentialPolyline(sections: any, color: any, key: any, name: any): JSX.Element {
    const pathOptions = { color };
    if (name === 'Référentiel' || name === 'Reste à filmer') {
        return (
            <LayersControl.Overlay
                checked
                name={`<div style="background-color:${color}; display:inline-block; width:20px;">&nbsp;</div> ${name}`}
                key={key}
            >
                <Polyline pathOptions={pathOptions} positions={sections} />
            </LayersControl.Overlay>
        );
    }
    return (
        <LayersControl.Overlay name={`<div style="background-color:${color}; display:inline-block; width:20px;">&nbsp;</div> ${name}`} key={key}>
            <Polyline pathOptions={pathOptions} positions={sections} />
        </LayersControl.Overlay>
    );
}

/* eslint-disable @typescript-eslint/no-unused-vars */

function MapWrapper({
    initialCenter = [45.2167723, 5.8073047],
}: Props): JSX.Element | null {
    const { tid, jid } = useParams<{ tid: string, jid: string }>();
    const [sections, setSections]: any[] = useState(null);
    const [referential, setReferential]: any[] = useState(null);
    const [panoptesUri, setPanoptesUri]: any[] = useState();

    useEffect(() => {
        const fetchPanoptesUri = async (): Promise<any> => {
            const requestOptions = {
                method: 'GET',
            };

            const rawResponse = fetch(
                '/api/server/about',
                requestOptions,
            );
            return rawResponse;
        };
        fetchPanoptesUri()
            .then((res): any => {
                if (res.status >= 400) {
                    setPanoptesUri(null);
                } else {
                    res.json().then((resolvedAbout: any) => setPanoptesUri(resolvedAbout.panoptes));
                }
            });
    }, []);

    useEffect(() => {
        const fetchReferential = async (): Promise<any> => {
            const requestOptions = {
                method: 'GET',
            };

            const rawResponse = fetch(
                `${panoptesUri}/api/v1.0/projects/referential/task/${tid}`,
                requestOptions,
            );
            return rawResponse;
        };
        fetchReferential()
            .then((res): any => {
                if (res.status >= 400) {
                    setReferential(null);
                } else {
                    res.json().then((resolvedReferential: any) => setReferential(resolvedReferential));
                }
            });
    }, [panoptesUri]);

    useEffect(() => {
        const makeArrays = (r: any): any => {
            const res: any = {};
            for (let i = 0; i < r.length; i++) {
                const currtSection = r[i];
                let geometry = JSON.parse(currtSection.geometry);
                let key: any = currtSection.section_status;
                if (res[key] == null) {
                    res[key] = [];
                }
                if (geometry) {
                    res[key].push(
                        geometry.coordinates.map((c: any) => ({ lng: c[0], lat: c[1] })),
                    );
                }
                if (currtSection.section_status === 'Référentiel' && currtSection.raw_geometry) {
                    geometry = JSON.parse(currtSection.raw_geometry);
                    key = 'raw_referential';
                    if (res[key] == null) {
                        res[key] = [];
                    }
                    res[key].push(
                        geometry.coordinates.map((c: any) => ({ lng: c[0], lat: c[1] })),
                    );
                }
            }
            return res;
        };

        if (referential) {
            const arrsval = makeArrays(referential);

            setSections(arrsval);
        }
    }, [referential]);

    function makePolylines(arrays: any): any {
        if (!arrays) return null;
        const mapper: any = {
            Référentiel: 'Référentiel',
            'Reste à filmer': 'Reste à faire',
            Filmé: 'Relevé',
            'Uploadé en attente de validation': 'À intégrer',
            Vidéocodé: 'Vidéocodé',
            Inexploitable: 'Inexploitable',
            'Référentiel brut': 'raw_referential',
        };
        const polylineProperties = [];
        const mapperKeys = Object.keys(mapper);

        // Filmé = relevé + vidéocodé + à intégrer
        for (let i = 0; i < mapperKeys.length; i++) {
            if (mapperKeys[i] === 'Filmé') {
                const filmedArray = [].concat(
                    arrays[mapper[mapperKeys[i]]] || [],
                    arrays[mapper[mapperKeys[i + 1]]] || [],
                    arrays[mapper[mapperKeys[i + 2]]] || [],
                );
                polylineProperties.push({
                    sections: filmedArray,
                    color: colors[i],
                    index: i,
                    label: mapperKeys[i],
                });
            } else {
                polylineProperties.push({
                    sections: arrays[mapper[mapperKeys[i]]] || [],
                    color: colors[i],
                    index: i,
                    label: mapperKeys[i],
                });
            }
        }

        return (
            <LayersControl position='topright'>
                {polylineProperties.map((sectionData) => ReferentialPolyline(
                    sectionData.sections,
                    sectionData.color,
                    sectionData.index,
                    sectionData.label,
                ))}
            </LayersControl>
        );
    }

    return (
        <MapContainer
            center={initialCenter}
            zoom={18}
            style={{
                width: '100%',
                height: '100%',
                minHeight: '100%',
                minWidth: '100%',
            }}
        >
            <TileLayer
                url='https://dev-gme-ws.geoptis.com/raster-material/{z}/{x}/{y}.png '
            />
            {makePolylines(sections)}
            <MapEventHandler initialCenter={initialCenter} />
        </MapContainer>
    );
}

export default MapWrapper;
