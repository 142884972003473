// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { RawLabel, RawAttribute } from 'cvat-core-wrapper';

export interface SkeletonConfiguration {
    type: 'skeleton';
    svg: string;
    sublabels: RawLabel[];
}

export type LabelOptColor = RawLabel;

let id = 0;

function validateParsedAttribute(attr: RawAttribute): void {
    if (typeof attr.name !== 'string') {
        throw new Error(`Le nom de l'attribut doit être une chaîne de charactères. Valeur récupérée ${attr.name}`);
    }

    if (!['number', 'undefined'].includes(typeof attr.id)) {
        throw new Error(
            `Attribute: "${attr.name}". L'id de l'attribut doit être un nombre ou indéfini. Valeur récupérée ${attr.id}`,
        );
    }

    if (!['checkbox', 'number', 'text', 'radio', 'select'].includes((attr.input_type || '').toLowerCase())) {
        throw new Error(`Attribute: "${attr.name}". Type de l'entrée inconnu : ${attr.input_type}`);
    }

    if (typeof attr.mutable !== 'boolean') {
        throw new Error(`Attribute: "${attr.name}". Le drapeau muable doit être un booléen. Valeur récupérée ${attr.mutable}`);
    }

    if (!Array.isArray(attr.values)) {
        throw new Error(`Attribute: "${attr.name}". Les valeurs d'attributs doivent être sous forme de liste. Type récupéré ${typeof attr.values}`);
    }

    if (!attr.values.length) {
        throw new Error(`Attribute: "${attr.name}". Attribute values array mustn't be empty`);
    }

    for (const value of attr.values) {
        if (typeof value !== 'string') {
            throw new Error(`Attribute: "${attr.name}". Chaque valeur doit être une chaîne de charactères. Valeur récupérée ${value}`);
        }
    }
}

export function validateParsedLabel(label: RawLabel): void {
    if (typeof label.name !== 'string') {
        throw new Error(`Le nom du label doit être une chaîne de charactères. Valeur récupérée ${label.name}`);
    }

    if (!['number', 'undefined'].includes(typeof label.id)) {
        throw new Error(
            `Label "${label.name}". L'id du label doit être un nombre ou indéfini. Valeur récupérée ${label.id}`,
        );
    }

    if (label.color && typeof label.color !== 'string') {
        throw new Error(`Label "${label.name}". La couleur du label doit être une chaîne de charactères. Type récupéré ${typeof label.color}`);
    }

    if (label.color && !label.color.match(/^#[0-9a-fA-F]{6}$|^$/)) {
        throw new Error(
            `Label "${label.name}". ` +
                ` La couleur du label peut seulement être une chaîne de charactères valide. Valeur récupérée ${label.color}`,
        );
    }

    if (!Array.isArray(label.attributes)) {
        throw new Error(`Label "${label.name}". Les valeurs d'attributs doivent être sous forme de liste. Type récupéré ${typeof label.attributes}`);
    }

    for (const attr of label.attributes) {
        validateParsedAttribute(attr);
    }
}

export function idGenerator(): number {
    return --id;
}

export function equalArrayHead(arr1: string[], arr2: string[]): boolean {
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function toSVGCoord(svg: SVGSVGElement, coord: number[], raiseError = false): number[] {
    const result = [];
    const ctm = svg.getScreenCTM();

    if (!ctm) {
        if (raiseError) throw new Error('Screen CTM is null');
        return coord;
    }

    const inversed = ctm.inverse();
    if (!inversed) {
        if (raiseError) throw new Error('Inversed screen CTM is null');
        return coord;
    }

    for (let i = 0; i < coord.length; i += 2) {
        let point = svg.createSVGPoint();
        point.x = coord[i];
        point.y = coord[i + 1];
        point = point.matrixTransform(inversed);
        result.push(point.x, point.y);
    }

    return result;
}

export function fromSVGCoord(svg: SVGSVGElement, coord: number[], raiseError = false): number[] {
    const result = [];
    const ctm = svg.getCTM();
    if (!ctm) {
        if (raiseError) throw new Error('Inversed screen CTM is null');
        return coord;
    }

    for (let i = 0; i < coord.length; i += 2) {
        let point = svg.createSVGPoint();
        point.x = coord[i];
        point.y = coord[i + 1];
        point = point.matrixTransform(ctm);
        result.push(point.x, point.y);
    }

    return result;
}
