// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { BoundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActions, AuthActionTypes } from 'actions/auth-actions';
import { ShortcutsActions, ShortcutsActionsTypes } from 'actions/shortcuts-actions';
import { KeyMap, KeyMapItem } from 'utils/mousetrap-react';
import { DimensionType, ShortcutsState } from '.';

function formatShortcuts(shortcuts: KeyMapItem): string {
    const list: string[] = shortcuts.displayedSequences || (shortcuts.sequences as string[]);
    return `[${list
        .map((shortcut: string): string => {
            let keys = shortcut.split('+');
            keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
            keys = keys.join('+').split(/\s/g);
            keys = keys.map((key: string): string => `${key ? key[0].toUpperCase() : key}${key.slice(1)}`);
            return keys.join(' ');
        })
        .join(', ')}]`;
}

const defaultKeyMap = ({
    SWITCH_SHORTCUTS: {
        name: 'Monter les raccourcis',
        description: 'Montrer/cacher la liste des raccourcis disponibles',
        sequences: ['f1'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_SETTINGS: {
        name: 'Montrer les paramètres',
        description: 'Montrer/cacher la fenêtre de paramètres',
        sequences: ['f2'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },

    SWITCH_ALL_LOCK: {
        name: 'Verrouiller/déverouiller tous les objets',
        description: 'Changer l état "verouillé" pour tous les objets dans la barre latérale',
        sequences: ['t l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_LOCK: {
        name: 'Verrouiller/déverouiller un objet',
        description: 'Changer l état "verouillé" pour l\'objet actif',
        sequences: ['l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_ALL_HIDDEN: {
        name: 'Montrer/cacher tous les objets',
        description: 'Changer l état "caché" pour tous les objets dans la barre latérale',
        sequences: ['t h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_HIDDEN: {
        name: 'Montrer/cacher un objet',
        description: 'Changer l état "caché" pour un objet',
        sequences: ['h'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_OCCLUDED: {
        name: 'Changer la propriété d\'occlusion',
        description: 'Changer la propriété d\'occlusion pour un objet',
        sequences: ['q', '/'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_KEYFRAME: {
        name: 'Changer la propriété "frame clé"',
        description: 'Changer la propriété "frame clé" pour un objet',
        sequences: ['k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_OUTSIDE: {
        name: 'Changer la propriété en dehors',
        description: 'Changer la propriété en dehors pour la track',
        sequences: ['o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    DELETE_OBJECT: {
        name: 'Supprimer un objet',
        description: 'Supprimer un objet. Utiliser shift pour forcer la suppression d\'objet verrouillé',
        sequences: ['del', 'shift+del'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TO_BACKGROUND: {
        name: 'Arrière plan',
        description: 'Mettre l\'objet plus loin de l\'objet plus loin de l\'utilisateur (diminuer la valeur sur axe z)',
        sequences: ['-', '_'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    TO_FOREGROUND: {
        name: 'Premier plan',
        description: 'Mettre l\'objet plus loin de l\'objet plus près de l\'utilisateur (augmenter la valeur sur axe z)',
        sequences: ['+', '='],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    COPY_SHAPE: {
        name: 'Copier la forme',
        description: 'Copier la forme',
        sequences: ['ctrl+c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PROPAGATE_OBJECT: {
        name: 'Propager objet',
        description: 'Copier objet sur la frame suivante',
        sequences: ['ctrl+b'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_KEY_FRAME: {
        name: 'Frame "clé" suivante',
        description: 'Aller à la prochaine frame "cléé sur la track',
        sequences: ['r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    PREV_KEY_FRAME: {
        name: 'Frame "clé" précédente',
        description: 'Aller à la précedénte frame "cléé sur la track',
        sequences: ['e'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },

    NEXT_ATTRIBUTE: {
        name: 'Attribut suivant',
        description: 'Aller à l attribut suivant',
        sequences: ['down'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_ATTRIBUTE: {
        name: 'Attribut précédent',
        description: 'Aller à l attribut précédent',
        sequences: ['up'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_OBJECT: {
        name: 'Objet suivant',
        description: 'Aller à l objet suivant',
        sequences: ['tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREVIOUS_OBJECT: {
        name: 'Objet précédent',
        description: 'Aller à l objet précédent',
        sequences: ['shift+tab'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },

    PASTE_SHAPE: {
        name: 'Coller la forme',
        description: 'Coller la forme',
        sequences: ['ctrl+v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_DRAW_MODE: {
        name: 'Mode dessin',
        description:
            'Répéter la dernière procedure dessin avec les mêmes paramètres (shift pour redessiner une forme existante)',
        sequences: ['shift+n', 'n'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    OPEN_REVIEW_ISSUE: {
        name: 'Ecrire un commentaire',
        description: 'Ecrire un nouveau commentaire dans le workspace',
        sequences: ['n'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_MERGE_MODE: {
        name: 'Mode merge',
        description: 'Activer ou desactiver le mode pour fusionner les formes',
        sequences: ['m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_SPLIT_MODE: {
        name: 'Mode split',
        description: 'Activer ou desactiver le mode pour séparer les formes',
        sequences: ['alt+m'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_GROUP_MODE: {
        name: 'Mode groupe',
        description: 'Activer ou desactiver le mode pour grouper les formes',
        sequences: ['g'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    RESET_GROUP: {
        name: 'Reset groupe',
        description: 'Reset le groupe pour les formes séléctionnées (en mode groupe)',
        sequences: ['shift+g'],
        action: 'keyup',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    CANCEL: {
        name: 'Annuler',
        description: 'Annuler tous les modes actifs',
        sequences: ['esc'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    CLOCKWISE_ROTATION: {
        name: 'Pivoter dans le sens horraire',
        description: 'Ajouter 90 degrés',
        sequences: ['ctrl+r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    ANTICLOCKWISE_ROTATION: {
        name: 'Pivoter dans le sens antihorraire',
        description: 'Soustraire 90 degrés',
        sequences: ['ctrl+shift+r'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },

    SAVE_JOB: {
        name: 'Enregistrer le travail',
        description: 'Enregistrer les annotations',
        sequences: ['ctrl+s'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    UNDO: {
        name: 'Annuler action',
        description: 'Annuler la dernière action',
        sequences: ['ctrl+z'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    REDO: {
        name: 'Restaurer action',
        description: 'Restaurer action',
        sequences: ['ctrl+shift+z', 'ctrl+y'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    NEXT_FRAME: {
        name: 'Frame suivante',
        description: 'Aller à la frame suivante',
        sequences: ['f'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PREV_FRAME: {
        name: 'Frame précédente',
        description: 'Aller à la frame précédente',
        sequences: ['d'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FORWARD_FRAME: {
        name: 'Frame en avant',
        description: 'Aller en avant avec un pas',
        sequences: ['v'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    BACKWARD_FRAME: {
        name: 'Frame en arrière',
        description: 'Aller en arrière avec un pas',
        sequences: ['c'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_FORWARD: {
        name: 'Chercher en avant',
        description: 'Chercher la prochaine frame qui satisfait les filtres',
        sequences: ['right'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SEARCH_BACKWARD: {
        name: 'Chercher en arrière',
        description: 'Chercher la précédente frame qui satisfait les filtres',
        sequences: ['left'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    PLAY_PAUSE: {
        name: 'Lecture/pause',
        description: 'Lecture/pause automatique',
        sequences: ['space'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    FOCUS_INPUT_FRAME: {
        name: 'Focus frame entrée',
        description: 'Focus sur l element pour changer la frame courante',
        sequences: ['`'],
        displayedSequences: ['~'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_AUTOMATIC_BORDERING: {
        name: 'Changer bordure automatique',
        description: 'Changer bla ordure automatique pour les polygones et polylignes pendant le dessin/edition',
        sequences: ['ctrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    SWITCH_TOOLS_BLOCKER_STATE: {
        name: 'Switch algorithm blocker',
        description: 'Postpone running the algorithm for interaction tools',
        sequences: ['сtrl'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    CHANGE_OBJECT_COLOR: {
        name: 'Changer la couleur',
        description: 'Mettre la couleur suivante pour une forme activée',
        sequences: ['enter'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TOGGLE_LAYOUT_GRID: {
        name: 'Toggle layout grid',
        description: 'The grid is used to UI development',
        sequences: ['ctrl+alt+enter'],
        action: 'keydown',
        applicable: [DimensionType.DIM_2D],
    },
    SWITCH_LABEL: {
        name: 'Switch label',
        description: 'Changes a label for an activated object or for the next drawn object if no objects are activated',
        sequences: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map((val: string): string => `ctrl+${val}`),
        action: 'keydown',
        applicable: [DimensionType.DIM_2D, DimensionType.DIM_3D],
    },
    TILT_UP: {
        name: 'Camera Roll Angle Up',
        description: 'Increases camera roll angle',
        sequences: ['shift+arrowup'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    TILT_DOWN: {
        name: 'Camera Roll Angle Down',
        description: 'Decreases camera roll angle',
        sequences: ['shift+arrowdown'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_LEFT: {
        name: 'Camera Pitch Angle Left',
        description: 'Decreases camera pitch angle',
        sequences: ['shift+arrowleft'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ROTATE_RIGHT: {
        name: 'Camera Pitch Angle Right',
        description: 'Increases camera pitch angle',
        sequences: ['shift+arrowright'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_UP: {
        name: 'Camera Move Up',
        description: 'Move the camera up',
        sequences: ['alt+u'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_DOWN: {
        name: 'Camera Move Down',
        description: 'Move the camera down',
        sequences: ['alt+o'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_LEFT: {
        name: 'Camera Move Left',
        description: 'Move the camera left',
        sequences: ['alt+j'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    MOVE_RIGHT: {
        name: 'Camera Move Right',
        description: 'Move the camera right',
        sequences: ['alt+l'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_IN: {
        name: 'Camera Zoom In',
        description: 'Performs zoom in',
        sequences: ['alt+i'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    ZOOM_OUT: {
        name: 'Camera Zoom Out',
        description: 'Performs zoom out',
        sequences: ['alt+k'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
    CANCEL_SKELETON_EDGE: {
        name: 'Cancel skeleton drawing',
        description: 'Interrupts drawing a new skeleton edge',
        sequences: ['esc'],
        action: 'keydown',
        applicable: [DimensionType.DIM_3D],
    },
} as any) as KeyMap;

const defaultState: ShortcutsState = {
    visibleShortcutsHelp: false,
    keyMap: defaultKeyMap,
    normalizedKeyMap: Object.keys(defaultKeyMap).reduce((acc: Record<string, string>, key: string) => {
        const normalized = formatShortcuts(defaultKeyMap[key]);
        acc[key] = normalized;
        return acc;
    }, {}),
};

export default (state = defaultState, action: ShortcutsActions | BoundariesActions | AuthActions): ShortcutsState => {
    switch (action.type) {
        case ShortcutsActionsTypes.SWITCH_SHORTCUT_DIALOG: {
            return {
                ...state,
                visibleShortcutsHelp: !state.visibleShortcutsHelp,
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        default: {
            return state;
        }
    }
};
