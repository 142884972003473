import AntdConfig from 'react-awesome-query-builder/lib/config/antd';

export const CommonFilterConfiguration = {
  operators: {
    ...AntdConfig.operators,
    between: {
      ...AntdConfig.operators.between,
      label: 'entre',
    },
    like: {
      ...AntdConfig.operators.like,
      label: 'comme',
    },
    select_any_in: {
      ...AntdConfig.operators.select_any_in,
      label: "n'importe parmi"
    },
    select_not_any_in: {
      ...AntdConfig.operators.select_not_any_in,
      label: "n'est pas parmi"
    },
    is_null: {
      ...AntdConfig.operators.is_null,
      label: "est nul"
    },
    is_not_null : {
      ...AntdConfig.operators.is_not_null,
      label: "n'est pas nul",
    },
  },
  conjunctions: {
    ...AntdConfig.conjunctions,
      AND: {
        ...AntdConfig.conjunctions.AND,
        label :'Et'
      },
      OR: {
        ...AntdConfig.conjunctions.OR,
        label : 'Ou'
      }
  },
  settings: {
    ...AntdConfig.settings,
    valueLabel:"Valeur",
    valuePlaceholder: "Valeur",
    fieldLabel: "Champ",
    operatorLabel: "Opérateur",
    funcLabel: "Fonction",
    fieldPlaceholder: "Sélectionner un champ",
    funcPlaceholder: "Sélectionner une fonction",
    operatorPlaceholder: "Sélectionner un opérateur",
    lockLabel: "Vérouiller",
    lockedLabel: "Vérouillé",
    addGroupLabel: 'Ajouter un groupe',
    addRuleLabel: 'Ajouter une règle',
    addSubRuleLabel: "Ajouter une sous-règle",
    notLabel: "Non",
    valueSourcesPopupTitle:"Sélectionner la source des valeurs",
  },
}