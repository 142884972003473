import React from 'react';

export default function ProgressBar(props: Props): JSX.Element {
  const { percent } = props;

  const containerStyles = {
    height: 20,
    width: '30%',
    backgroundColor: 'white',
    backgroundColor: '#f1f1f1',
    borderRadius: 50,
    zIndex: 2,
  }

  const fillerStyles = {
    height: '100%',
    width: `${percent}%`,
    backgroundColor: 'blue',
    backgroundColor: '#1890ff',
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const labelStyles = {
    padding: 5,
    color: 'black',
  }

  return (
    (percent === 100) ? null : (
    <div className="progress-bar-div" style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${percent}%`}</span>
      </div>
    </div>)
  )
}
