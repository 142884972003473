// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from 'antd/lib/layout';
import Result from 'antd/lib/result';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';

import AttributeAnnotationWorkspace from 'components/annotation-page/attribute-annotation-workspace/attribute-annotation-workspace';
import ReviewAnnotationsWorkspace from 'components/annotation-page/review-workspace/review-workspace';
import StandardWorkspaceComponent from 'components/annotation-page/standard-workspace/standard-workspace';
import StandardWorkspace3DComponent from 'components/annotation-page/standard3D-workspace/standard3D-workspace';
import TagAnnotationWorkspace from 'components/annotation-page/tag-annotation-workspace/tag-annotation-workspace';
import FiltersModalComponent from 'components/annotation-page/top-bar/filters-modal';
import StatisticsModalComponent from 'components/annotation-page/top-bar/statistics-modal';
import AnnotationTopBarContainer from 'containers/annotation-page/top-bar/top-bar';
import { Workspace } from 'reducers';
import { usePrevious, useInterval } from 'utils/hooks';
import './styles.scss';
import Button from 'antd/lib/button';
import ProgressBar from './progress-bar/progress-bar';

interface Props {
    job: any | null | undefined;
    fetching: boolean;
    frameNumber: number;
    workspace: Workspace;
    preFetchStatus: any;
    getJob(): void;
    saveLogs(): void;
    closeJob(): void;
    changeFrame(frame: number): void;
    getPreFetchingStatus(): void;
}

export default function AnnotationPageComponent(props: Props): JSX.Element {
    const {
        job, fetching, workspace, frameNumber, preFetchStatus, getJob, closeJob, saveLogs, changeFrame, getPreFetchingStatus
    } = props;
    const prevJob = usePrevious(job);
    const prevFetching = usePrevious(fetching);

    // Task id et job id
    const { tid, jid } = useParams<{ tid: string, jid: string }>();

    // Géolocalisation associée à chaque frame
    const [framePositions, setFramePositions] = useState<any>();

    useEffect(() => {
        const method = 'GET';
        fetch(`/api/tasks/${tid}`, { method }).then((res) => {
            res.json().then((r) => {
                setFramePositions(r.geolocation);
            });
        });
    }, []);

    // Conteneur pour la fenêtre dans laquelle la map s'ouvre
    const [container, setContainer] = useState<Element | null>(null);

    // Permet de déplacer le pointeur sur la carte
    const [mapBroadcastChannel] = useState<BroadcastChannel>(new BroadcastChannel(`cvat_${tid}_${jid}`));

    // Permet de se déplacer dans le tableau des positions GPS
    const frameBroadcastChannel = new BroadcastChannel(`cvat_frame_${jid}`);

    const [currentFrame, setCurrentFrame] = useState(0);

    frameBroadcastChannel.onmessage = (e: MessageEvent<number>) => {
        if (framePositions) {
            setCurrentFrame(e.data);
        }
    };

    useEffect(() => {
        if (framePositions && mapBroadcastChannel) {
            // Si l'ordre était préservé, on pourrait faire ceci :
            // const framePos = framePositions[currentFrame];

            // Mais on n'a pas de garantie que l'ordre soit conservé et envoyé dans le bon ordre par le backend django
            // Donc il faut utiliser find
            const framePos = framePositions.find((x: any) => x.frame_number === currentFrame);
            if (framePos) {
                const center = [parseFloat(framePos.latitude), parseFloat(framePos.longitude)];

                const c = JSON.parse(JSON.stringify(center));
                mapBroadcastChannel.postMessage({ id: 0, coords: c });
            }
        }
    }, [currentFrame]);

    const newWindow = useRef<Window | null>(null);

    useEffect(() => {
        const minimap_enabled = process.env.WITH_MINIMAP;
        if (container && minimap_enabled) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            newWindow.current = window.open(
                `/tasks/${tid}/jobs/${jid}/map`,
                '_blank',
                'width=1024,height=768,left=20,top=20',
            );

            const curWindow = newWindow.current;
            return () => curWindow?.close();
        }
        return () => newWindow?.current?.close();
    }, [container]);

    useEffect(() => {
        const node = document.createElement('div');
        node.id = 'leaflet_root';
        setContainer(node);
    }, []);

    const history = useHistory();
    useEffect(() => {
        saveLogs();
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '720px';
        }

        return () => {
            saveLogs();
            if (root) {
                root.style.minHeight = '';
            }

            if (!history.location.pathname.includes('/jobs')) {
                closeJob();
            }
        };
    }, []);

    useEffect(() => {
        if (job === null && !fetching) {
            getJob();
        }
    }, [job, fetching]);

    useInterval(() => {
        if (job) {
            getPreFetchingStatus();
        }
    }, 5000);

    useEffect(() => {
        if (prevFetching && !fetching && !prevJob && job) {
            const latestFrame = localStorage.getItem(`Job_${job.id}_frame`);
            if (latestFrame && Number.isInteger(+latestFrame)) {
                const parsedFrame = +latestFrame;
                if (parsedFrame !== frameNumber && parsedFrame >= job.startFrame && parsedFrame <= job.stopFrame) {
                    const notificationKey = `cvat-notification-continue-job-${job.id}`;
                    notification.info({
                        key: notificationKey,
                        message: `Vous avez fini de travailler sur la frame ${parsedFrame}`,
                        description: (
                            <span>
                                Cliquez
                                <Button
                                    className='cvat-notification-continue-job-button'
                                    type='link'
                                    onClick={() => {
                                        changeFrame(parsedFrame);
                                        notification.close(notificationKey);
                                    }}
                                >
                                    ici
                                </Button>
                                pour reprendre votre travail
                            </span>
                        ),
                        placement: 'topRight',
                        className: 'cvat-notification-continue-job',
                    });
                }
            }

            if (!job.labels.length) {
                notification.warning({
                    message: 'Pas de labels',
                    description: (
                        <span>
                            {`${job.projectId ? 'Clavier' : 'Tâche'} ${job.projectId || job.taskId
                                } ne contient aucun label. `}
                            <a href={`/${job.projectId ? 'clavier' : 'tâche'}/${job.projectId || job.id}/`}>
                                Ajouter
                            </a>
                            {' le premier pour éditer l\'annotation.'}
                        </span>
                    ),
                    placement: 'topRight',
                    className: 'cvat-notification-no-labels',
                });
            }
        }
    }, [job, fetching, prevJob, prevFetching]);

    if (job === null) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (typeof job === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title='Désolé mais ce travail n a pas été trouvé'
                subTitle='S il-vous-plaît, soyez sûr que l information à laquelle vous souhaitez accéder existe et que vous avez les autorisations nécessaires.'
            />
        );
    }

    return (
        <>
            <ProgressBar className="progress-bar" percent={Math.floor(preFetchStatus.current / preFetchStatus.max * 100)}></ProgressBar>
            <Layout className='cvat-annotation-page'>
                {workspace === Workspace.STANDARD3D && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <StandardWorkspace3DComponent />
                    </Layout.Content>
                )}
                {workspace === Workspace.STANDARD && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <StandardWorkspaceComponent />
                    </Layout.Content>
                )}
                {workspace === Workspace.ATTRIBUTE_ANNOTATION && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <AttributeAnnotationWorkspace />
                    </Layout.Content>
                )}
                {workspace === Workspace.TAG_ANNOTATION && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <TagAnnotationWorkspace />
                    </Layout.Content>
                )}
                {workspace === Workspace.REVIEW_WORKSPACE && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <ReviewAnnotationsWorkspace />
                    </Layout.Content>
                )}
                <FiltersModalComponent />
                <StatisticsModalComponent />
                <Layout.Header className='cvat-annotation-header'>
                    <AnnotationTopBarContainer />
                </Layout.Header>
            </Layout>
        </>
    );
}
